<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in dtlFlds"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";

export default {
  name: "Registry",
  mixins: [ShowMixin, FormMixin],
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "claim",
      isLoading: true,
      status_registry_value: 0,
      detailFields: {
        status_registry_0: {
          identificativi: {
            header: "Dati Anagrafici",
            edit: false,
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                value: "status_registry.text",
              },
              {
                label: this.getDictionary("attribute_CHAN_value", "registry"),
                value: "attributables.CHAN",
              },
              {
                label: this.getDictionary("attribute_TITL_value", "registry"),
                value: "attributables.TITL",
              },
              {
                label: this.getDictionary("attribute_NAME_value", "registry"),
                value: "attributables.NAME",
              },
              {
                label: this.getDictionary("attribute_SURN_value", "registry"),
                value: "attributables.SURN",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "registry"),
                value: "attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_MARS_value", "registry"),
                value: "attributables.MARS",
              },
              {
                label: this.getDictionary("attribute_PROF_value", "registry"),
                value: "attributables.PROF",
              },
              {
                label: this.getDictionary("attribute_POB_value", "registry"),
                model: "attributables.POB",
                value: "attributables.POB",
              },
              {
                label: this.getDictionary("attribute_DOB_value", "registry"),
                value: "attributables.DOB",
              },
              {
                label: this.getDictionary("attribute_SEX_value", "registry"),
                value: "attributables.SEX",
              },
            ],
          },
          nascita: {
            header: "Nato a:",
            repository: "registry",
            edit: false,
            fields: [
              {
                label: this.getDictionary("attribute_POB_value", "registry"),
                value: "attributables.POB",
              },
              {
                label: this.getDictionary("attribute_DOB_value", "registry"),
                value: "attributables.DOB",
              },
            ],
          },
          residenza: {
            header: "Residenza / Sede Legale",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 0,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
            ],
          },
          corrispondenza: {
            header: "Indirizzo di Corrispondenza",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_NAME2_value",
                  "correspondence"
                ),
                value: "attributables.NAME2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 1,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
            ],
          },
        },
        status_registry_1: {
          identificativi: {
            header: "Dati Identificativi",
            edit: false,
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("attribute_CNAM_value", "registry"),
                value: "attributables.CNAM",
              },
              {
                label: this.getDictionary("attribute_CREG_value", "registry"),
                value: "attributables.CREG",
              },
            ],
          },
          residenza: {
            header: "Residenza / Sede Legale",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 0,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
            ],
          },
          corrispondenza: {
            header: "Indirizzo di Corrispondenza",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_NAME2_value",
                  "correspondence"
                ),
                value: "attributables.NAME2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 1,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
            ],
          },
        },
        scheda_recapito_1: {
          header: "Recapiti Primari",
          edit: false,
          repository: "address_book",
          fields: [
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "attributables.CELL",
              relation: "address_books",
              type: "tel",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PHON_value", "address_book"),
              value: "attributables.PHON",
              relation: "address_books",
              type: "tel",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_FAX_value", "address_book"),
              value: "attributables.FAX",
              relation: "address_books",
              type: "tel",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "attributables.MAIL",
              relation: "address_books",
              rules: { email: true },
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PEC_value", "address_book"),
              value: "attributables.PEC",
              relation: "address_books",
              rules: { email: true },
              status: 0,
            },
            {
              label: this.getDictionary("attribute_WEB_value", "address_book"),
              value: "attributables.WEB",
              relation: "address_books",
              status: 0,
            },
          ],
        },
        primary_group: {
          header: "Gruppo Primario",
          edit: false,
          fields: [
            {
              label: this.getDictionary("primary_group"),
              value: "registry_group",
              virtual: (detail) => {
                const found = detail.registry_groups.find(
                  (registry_group) => registry_group.pivot.primary.value === "Y"
                );
                return found ? found.code : "";
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    fetch() {
      this.isLoading = true;
      this.fetchShowForm(
        this.repository,
        this.resourceId,
        "relations/byRegistryGroup"
      )
        .then((response) => {
          const data = response.data.data;
          this.details = data.insurance_policy.registry;
          this.status_registry_value =
            data.insurance_policy.registry.status_registry.value;
          this.initDetailCardFormData();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetch();
  },
  computed: {
    dtlFlds() {
      // mergia i campi di status 0 o 1 con quelli in comune
      let { status_registry_0, status_registry_1, ...rest } = this.detailFields;
      let c = this.status_registry_value
        ? status_registry_1
        : status_registry_0;
      let r = { ...c, ...rest };
      return r;
    },
  },
};
</script>
