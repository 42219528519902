<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(address)="{ item }">
            <span v-html="toInfoData(item, 'address', 0)"></span>
          </template>
          <template #cell(phone)="{ item }">
            <div v-if="item['address_books']">
              {{
                toRelationString(
                  "address_books",
                  item.address_books,
                  ["PHON"],
                  [0]
                )
              }}
            </div>
          </template>
          <template #cell(cell)="{ item }">
            <div v-if="item['address_books']">
              {{
                toRelationString(
                  "address_books",
                  item.address_books,
                  ["CELL"],
                  [0]
                )
              }}
            </div>
          </template>
          <template #cell(email)="{ item }">
            <div v-if="item['address_books']">
              {{
                toRelationString(
                  "address_books",
                  item.address_books,
                  ["MAIL"],
                  [0]
                )
              }}
            </div>
          </template>
          <template #cell(pec)="{ item }">
            <div v-if="item['address_books']">
              {{
                toRelationString(
                  "address_books",
                  item.address_books,
                  ["PEC"],
                  [0]
                )
              }}
            </div>
          </template>
          <template
            v-slot:head(check)="data"
            v-if="hasChecks && !isCheckExclusive && items.length"
          >
            <base-checkbox
              v-model="checkAll"
              name="all"
              class="check"
              @change="onSelectAllRows"
              ><span v-if="data.label" v-html="data.label"></span>
            </base-checkbox>
          </template>
          <template #cell(check)="row" v-if="hasChecks">
            <base-checkbox
              name="check"
              class="check"
              v-model="row.item.check"
              @input="onSelectRow($event, row.index, row.item)"
            />
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onCustomEdit(row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import BaseCheckbox from "@/components/form/BaseCheckbox";

import moment from "moment";
moment.locale("it");

export default {
  name: "ClaimPartiesTable",
  extends: template,
  data() {
    return {};
  },
  components: {
    BaseIcon,
    ButtonGroupTab,
    BaseCheckbox,
  },
  methods: {
    customized(item) {
      console.debug(item);
    },
    onCustomEdit(item) {
      this.$emit("checkClaimIsImported", item);
      this.onEdit(item.id, item);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.check) {
  width: 1px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
</style>
