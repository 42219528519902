<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(contraente)="{ item }" v-if="items.length">
            <span v-html="toInfoData(item.registry, 'registryfullname')"></span>
          </template>
          <template #cell(delay_days)="{ item }" v-if="items.length">
            {{
              item.delay_days ? item.delay_days : item.insurance_risk.delay_days
            }}
          </template>
          <template #cell(compagnia)="{ item }" v-if="items.length">
            {{
              item.insurer ? `${item.insurer.code}-${item.insurer.title}` : ""
            }}
          </template>
          <template #cell(branch_risk)="{ item }" v-if="items.length">
            {{
              item.insurance_risk
                ? `${item.insurance_risk.risk_branch.code}-${item.insurance_risk.code}-${item.insurance_risk.formatted_title}`
                : ""
            }}
          </template>
          <template v-slot:[riskBranchTitle]="row">
            <!-- Ramo -->
            {{
              row.item?.insurance_risk?.risk_branch?.code
                ? `${row.item.insurance_risk.risk_branch.code}-`
                : ""
            }}{{ row.item.insurance_risk.risk_branch.title }}
          </template>
          <template #cell(insurance_risk)="{ item }" v-if="items.length">
            <!-- Rischio -->
            {{ item?.insurance_risk?.code ? `${item.insurance_risk.code}-` : ""
            }}{{ getRiskTypeDescription(item) }}
          </template>
          <template #cell(broker)="{ item }" v-if="items.length">
            <span v-html="toInfoData(item.broker, 'broker', 0)"></span>
          </template>
          <template #cell(collaborator)="{ item }" v-if="items.length">
            <span v-html="toInfoData(item.collaborator, 'broker', 1)"></span>
          </template>
          <template #cell(note_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenNoteModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(
                  row.item.tasks.filter((task) => task.task_type.value === 2),
                  'Note',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Creato da',
                      key: 'owner_id',
                      formatter: (val) => retriveOwner(val),
                    },
                    {
                      label: 'Data creazione',
                      key: 'created_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                row.item.tasks.filter((task) => task.task_type.value === 2)
                  .length
              }}
            </div>
          </template>
          <template #cell(document_counter)="row" v-if="items.length">
            <!-- <template #cell(document_counter)="{ item }" v-if="items.length"> -->
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenDocModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(row.item.documents, 'Documenti', [
                  { label: 'Titolo', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  {
                    label: 'Ultimo aggiornamento',
                    key: 'last_update',
                    formatter: (val) => {
                      return moment(val).format('DD/MM/YYYY HH:mm:ss');
                    },
                  },
                ])
              "
            >
              {{ row.item.documents.length }}
            </div>
          </template>
          <template
            #cell(gross_payment_splitting)="{ item }"
            v-if="items.length"
          >
            {{ toLocaleCurrency(splittingGrossPremium(item)) }}
          </template>
          <template #cell(primary_group)="{ item }" v-if="items.length">
            {{
                item.registry
                  ? item.registry.registry_groups.length && item.registry.registry_groups.find(
                    registry_group => registry_group.pivot.primary.value === 'Y'
                  ) ? item.registry.registry_groups.find(
                    registry_group => registry_group.pivot.primary.value === 'Y'
                  ).code: ""
                  : "",
            }}
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(rowSelector)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item)"
                class="mt-1 mr-1"
                title="Seleziona"
              >
                Seleziona
              </b-button>
            </div>
          </template>
          <template
            slot="bottom-row"
            slot-scope="data"
            v-if="includeBottomRow && items.length"
          >
            <td
              v-for="(field, i) in data.fields"
              :key="i"
              :class="`totalRow${
                i !== 0 && evaluateTdClass(field.key) ? ' text-right' : ''
              }`"
            >
              <b
                >{{ i === 0 ? "Totale: " : "" }}
                {{
                  i !== 0 && field.key === "gross_premium"
                    ? toLocaleCurrency(fieldSum(items, "gross_premium"))
                    : null
                }}
                {{
                  i !== 0 && field.key === "gross_payment_splitting"
                    ? toLocaleCurrency(
                        fieldSum(
                          items.map((item) => {
                            return {
                              gross_premium_splitting:
                                splittingGrossPremium(item),
                            };
                          }),
                          "gross_premium_splitting"
                        )
                      )
                    : null
                }}
              </b>
            </td>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row v-if="!noPagination">
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>

      <b-modal
        :id="infomodalName"
        @show="onModalShow"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  :dbRowData="dbRowData"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import General from "@/components/wallet/policies/details/General";
import TechnicalData from "@/components/wallet/policies/details/TechnicalData";
import Registry from "@/components/wallet/policies/details/Registry";
import Group from "@/components/wallet/policies/details/Group";
import Titles from "@/components/wallet/policies/details/Titles";
import Accident from "@/components/wallet/policies/details/Claims";
import Notes from "@/components/wallet/policies/details/Notes";
import Todo from "@/components/wallet/policies/details/Todo";
import Accounting from "@/components/wallet/policies/details/Accounting";
import Appointments from "@/components/wallet/policies/details/Appointments";
import Documents from "@/components/wallet/policies/details/Documents";
import Sepa from "@/components/wallet/policies/details/Sepa";
import { toLocaleCurrency } from "@/utils/strings";
import { sumBreakdowns } from "@/utils/breakdowns";
import { getRiskTypeDescription, getVirtualValue } from "@/utils/payments";
import { canModule } from "@/utils/permissions";
import { mapGetters } from "vuex";

import moment from "moment";
moment.locale("it");

export default {
  name: "PoliciesTable",
  extends: template,
  data() {
    return {
      tabs: [
        { name: "General", text: "Generale" },
        { name: "TechnicalData", text: "Dati tec." },
        { name: "Registry", text: "Contraente" },
        { name: "Titles", text: "Titoli" },
        { name: "Accounting", text: "Incassi" },
        { name: "Accident", text: "Sinistri" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Group", text: "Gruppi" },
        { name: "Documents", text: "Doc" },
      ],
      selected: "General",
      modalResourceId: null,
      dbRowData: null, // tab Contraente deve avere i dati della polizza per Voltura
      tabIndex: 0,
      risk_branch_title: "insurance_risk.risk_branch.title",
    };
  },
  props: {
    parentResourceId: {
      type: Number,
    },
  },
  components: {
    BaseIcon,
    BaseInputPagination,
    General,
    TechnicalData,
    Registry,
    Group,
    Titles,
    Accident,
    Notes,
    Accounting,
    Documents,
    Appointments,
    ButtonGroupTab,
    Todo,
    Sepa,
  },
  methods: {
    canModule,
    toLocaleCurrency,
    sumBreakdowns,
    getVirtualValue,
    getRiskTypeDescription,
    evaluateTdClass(key) {
      return ["gross_premium", "gross_payment_splitting"].includes(key);
    },
    retriveOwner(value) {
      if (!value) return "-";
      return (
        this.getUsers().find((user) => user.value === value).username ?? "-"
      );
    },
    onModalShow() {
      if (this.tabIndex < this.tabs.length)
        this.setTab(this.tabs[this.tabIndex], this.tabIndex);
    },
    onModalHidden() {
      this.setTab({ name: "General", text: "Generale" }, 0);
    },
    // OVERRIDE: c'è il filtro fittizio byTypology
    customOpenNoteModal(item, index, target) {
      this.tabIndex = 7; // TabIndex ID delle Note
      this.openModal(item, index, target);
    },
    customOpenDocModal(item, index, target) {
      this.tabIndex = 10; // TabIndex ID dei Documenti
      this.openModal(item, index, target);
    },
    keepFilter() {
      // preparo il criterio col filtro fittizio
      // process the byTypology filter -> transform it as byAttribute
      let filter =
        this.$parent.$data.filter || this.$parent.$parent.$data.filter;

      if (
        !filter ||
        (filter.filterInsurancePolicyDetail1 &&
          filter.filterInsurancePolicyDetail2)
      ) {
        return {};
      }
      let byA = filter.byAttribute.status_policy;
      let byT = filter.byTypology.status_policy;
      // NOTA: per spezzare la reattività del "filter" usare JSON.parse(JSON.stringify()) !!!
      // altrimenti criteria.byAttribute.status_policy = byT imposta anche this.filter.byAttribute.status_policy = byT
      // e i v-model delle 2 select vanno a ramengo...
      let name =
        this.$parent.$data.filterName || this.$parent.$parent.$data.filterName;
      let criteria = JSON.parse(JSON.stringify(filter[name] || filter));
      if (byT && !byA) {
        criteria.byAttribute.status_policy = byT;
        criteria.byTypology.status_policy = null;
      }

      this.saveFilterByName({ name, criteria });
      return filter;
    },
    customized(item) {
      let c = {};
      c.data_decorrenza = moment(item.effective_at).format("DD/MM/YYYY");
      c.data_scadenza = moment(item.expired_at).format("DD/MM/YYYY");
      c.data_copertura = moment(item.covered_at).format("DD/MM/YYYY");
      c.data_emissione = moment(item.issued_at).format("DD/MM/YYYY");
      let customAttributes = this.convertFromCustomAttributesToFields(
        this.customAttributes()(this.repository)
      );
      Object.keys(customAttributes).forEach((i) => {
        let value = customAttributes[i].key.split(".");
        let label = customAttributes[i].label;
        c[label] = item.attributables[value[value.length - 1]];
      });
      return c;
    },
    fieldSum(items, field) {
      items;
      return items
        .map((item) => item[field])
        .reduce((prev, next) => prev + next, 0);
    },
    splittingGrossPremium(item) {
      return (
        item.gross_premium *
        (12 / (parseInt(item.payment_splitting.value) || 12))
      );
    },
    rowClass(item) {
      let rowClass = "";
      if (!item || !item?.status_policy?.value) return rowClass;
      switch (item.status_policy.value) {
        case 0:
          /* Non Perfezionata | bianco */
          rowClass = "policy-status-1";
          break;
        case 1:
          /* Perfezionata | verde */
          rowClass = "policy-status-1";
          break;
        case 2:
          /* Sospesa | giallo */
          rowClass = "policy-status-2";
          break;
        default:
          /* Sostituita | rosso */
          rowClass = "policy-status-3";
          break;
      }
      return rowClass;
    },
    ...mapGetters("auth", {
      getUsers: "users",
    }),
  },
  computed: {
    riskBranchTitle() {
      return `cell(${this.risk_branch_title})`;
    },
  },
  beforeMount() {
    if (canModule("module.PROF.SEPA")) {
      this.tabs.push({ name: "Sepa", text: "SEPA" });
    }
  },
};
</script>

<style lang="scss" scoped>
/* ROSSO */
:deep(.policy-status-3 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(228, 33, 33, 0.3) 100%
  );
}
/* VERDE */
:deep(.policy-status-1 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(19, 172, 19, 0.4) 100%
  );
}
/* GIALLO */
:deep(.policy-status-2 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 204, 0, 0.4) 100%
  );
}
/* NEUTRO */
:deep(.policy-status-0 td:nth-child(2)) {
  background: rgb(255, 255, 255);
}
</style>
