<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <!--Dati Generali, Dati di Polizza, Dati anagrafici, Premio, Provv. Agenzia, Provv. Coll., Importi Documenti su Polizza -->
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>

        <!-- Forme di Pagamento -->
        <b-card
          header="true"
          header-tag="header"
          class="mt-2"
          v-if="checkTreasuryRelation.length"
        >
          <div
            slot="header"
            class="d-flex justify-content-between align-items-center"
          >
            <p class="mb-0 d-inline-block align-middle">
              <b>Forme di Pagamento</b>
            </p>
          </div>
          <b-card-text>
            <b-table
              striped
              hover
              :items="entryDetails"
              :fields="entryDetailFields"
            >
              <template v-slot:cell(treasury_code)="row">
                {{ row.item?.treasury?.code }}
              </template>
              <template v-slot:cell(treasury_title)="row">
                {{ row.item?.treasury?.title }}
              </template>
              <template v-slot:cell(amount)="row">
                {{ toLocaleCurrency(row.item.gross) }}
              </template>
            </b-table>
          </b-card-text>
        </b-card>

        <!-- COASSICURAZIONI, COASSICURAZIONI DETTAGLIO -->
        <div v-if="isDelegaNostra">
          <details-card
            v-for="(item, index) in coassFields"
            :key="index"
            :header="item.header"
            :edit="item.edit || false"
            :fields="item.fields"
            :beForm="beForm"
            :data="details"
            :repository="item.repository"
          >
          </details-card>
        </div>
        <b-card
          header="true"
          header-tag="header"
          class="mt-2"
          v-if="coassDetails.length && isDelegaNostra"
        >
          <div
            slot="header"
            class="d-flex justify-content-between align-items-center"
          >
            <p class="mb-0 d-inline-block align-middle">
              <b>Dettaglio Coassicurazioni</b>
            </p>
          </div>
          <b-card-text>
            <b-table
              striped
              hover
              :items="coassDetails"
              :fields="coassDetailFields"
              responsive
            >
              <!-- compagnia_delegata: code_importer - title_importer
              agenzia_delegata: code
              quote_attribuite: pivot.share -->
              <!-- { key: "compagnia_delegata", label: "Codice Compagnia" },
              { key: "agenzia_delegata", label: "Codice Agenzia" },
              { key: "descrizione", label: "Codice Agenzia" },
              { key: "participant_prov_purchase", label: "Provv.Acq.Agente" },
              { key: "participant_prov_take", label: "Provv.Inc.Agente" },
              { key: "net", label: "Imponibile" },
              { key: "tax", label: "Imposte" },
              { key: "gross", label: "Totale" }, -->
              <template v-slot:cell(compagnia_delegata)="row">
                {{ row.item.code_importer }} - {{ row.item.title_importer }}
              </template>
              <template v-slot:cell(agenzia_delegata)="row">
                {{ row.item.code }}
              </template>
              <template v-slot:cell(descrizione)="row">
                {{ row.item.title }}
              </template>
              <!-- <template v-slot:cell(quote_attribuite)="row">
                {{ row.item.pivot.share }}%
              </template> -->
              <template v-slot:cell(participant_prov_purchase)="row">
                {{ toLocaleCurrency(row.item.pivot.participant_prov_purchase) }}
              </template>
              <template v-slot:cell(participant_prov_take)="row">
                {{ toLocaleCurrency(row.item.pivot.participant_prov_take) }}
              </template>
              <template v-slot:cell(net)="row">
                {{ toLocaleCurrency(row.item.pivot.net) }}
              </template>
              <template v-slot:cell(tax)="row">
                {{ toLocaleCurrency(row.item.pivot.tax) }}
              </template>
              <template v-slot:cell(gross)="row">
                {{ toLocaleCurrency(row.item.pivot.gross) }}
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import DetailsCard from "@/components/DetailsCard";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData } from "@/utils/transforms";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [ShowMixin, FormMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "book_entry",
      // Entry Details Table
      checkTreasuryRelation: [],
      entryDetailFields: [
        { key: "treasury_code", label: "Codice Cassa" },
        { key: "treasury_title", label: "Tipo Cassa" },
        {
          key: "amount",
          label: "Importo",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      entryDetails: [],
      form: {
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
        book_entries: {
          entry_details: {},
        },
      },
      detailFields: {
        book_entry: {
          header: "Dati Generali",
          fields: [
            {
              label: this.getDictionary("effective_date", "book_entry"),
              value: "effective_date",
              type: "datepicker",
            },
            {
              label: this.getDictionary("book_date", "book_entry"),
              value: "book_date",
              type: "datepicker",
            },
            {
              label: this.getDictionary("insurer"),
              value: "insurer.code",
            },
            {
              label: this.getDictionary("salesman"),
              value: "salesman",
              virtual: (detail) => {
                return this.toInfoData(
                  detail.brokers[0],
                  "broker",
                  0,
                  null,
                  "\n"
                );
              },
            },
            {
              label: this.getDictionary("cooperator"),
              value: "cooperator",
              virtual: (detail) => {
                const take = detail?.entry_details?.filter(
                  (entry_detail) =>
                    !["EN", "PR"].includes(entry_detail?.treasury?.code)
                );
                if (
                  !detail.insurance_ancillaries[0]?.insurance_policy
                    ?.collaborator_id
                ) {
                  return this.toInfoData(
                    detail.brokers.find((e) => e.status_broker.value === 1),
                    "broker",
                    1,
                    null,
                    "\n"
                  );
                } else {
                  return `${this.toInfoData(
                    detail.brokers.find((e) => e.status_broker.value === 1),
                    "broker",
                    1,
                    null,
                    "\n"
                  )} (${take.length ? "Diretto" : "Tramite collaboratore"})`;
                }
              },
            },
            // {
            //   label: this.getDictionary("Nr. foglio cassa"),
            //   value: "book_entry_number",
            // },
            {
              label: this.getDictionary("sheet_date", "book_entry"),
              value: "sheet_date",
              type: "datepicker",
            },
            {
              label: this.getDictionary("broker_statement"),
              value: "broker_statement",
              virtual: (detail) => {
                if (detail?.broker_statement_id && detail?.broker_statement) {
                  const extraParams = {};
                  if (detail.broker_statement.id)
                    extraParams["id"] = detail.broker_statement.id;
                  if (detail.broker_statement.from)
                    extraParams["date_from"] = toLocaleDate(
                      detail.broker_statement.from
                    );
                  if (detail.broker_statement.to)
                    extraParams["date_to"] = toLocaleDate(
                      detail.broker_statement.to
                    );
                  if (detail.broker_statement.created_date)
                    extraParams["created_date"] = toLocaleDate(
                      detail.broker_statement.created_date
                    );
                  /*
                  `Rendicontato nel rendiconto n. ${
                    detail.broker_statement.id
                      ? detail.broker_statement.id
                      : "-"
                  } (dal ${
                    detail.broker_statement.from
                      ? toLocaleDate(detail.broker_statement.from)
                      : "-"
                  } al ${
                    detail.broker_statement.to
                      ? toLocaleDate(detail.broker_statement.to)
                      : "-"
                  }) emesso il ${
                    detail.broker_statement.created_date
                      ? toLocaleDate(detail.broker_statement.created_date)
                      : "-"
                  }`;
                  */
                  return this.getDictionary(
                    "broker_statement_included",
                    null,
                    "it",
                    false,
                    extraParams
                  );
                }
                return this.getDictionary("broker_statement_not_included");
              },
            },
          ],
        },
        insurance_policy: {
          header: "Dati di Polizza",
          fields: [
            {
              label: this.getDictionary("number", "insurance_policy"),
              value: "insurance_policy_number",
              virtual: (detail) => {
                return detail.various_accountings &&
                  detail.various_accountings.length
                  ? detail.various_accountings[0].pivot.insurance_policy_number
                  : detail.insurance_ancillaries &&
                    detail.insurance_ancillaries.length
                  ? detail.insurance_ancillaries[0].insurance_policy
                    ? detail.insurance_ancillaries[0].insurance_policy.number
                    : ""
                  : "";
              },
            },
            {
              label: this.getDictionary("code", "book_tag"),
              value: "book_tags",
              virtual: (detail) => {
                let book_tags = [];
                detail.book_tags.forEach((book_tag) =>
                  book_tags.push(`${book_tag.code}-${book_tag.title}`)
                );
                return book_tags.join(",");
              },
            },
            {
              label: this.getDictionary("risk_branch", "book_entry"),
              value: "insurance_policy_number",
              virtual: (detail) => {
                return detail.various_accountings &&
                  detail.various_accountings.length
                  ? detail.various_accountings[0].pivot.insurance_risk
                      .risk_branch.code
                  : detail.insurance_ancillaries &&
                    detail.insurance_ancillaries.length
                  ? detail.insurance_ancillaries[0].insurance_policy
                    ? detail.insurance_ancillaries[0].insurance_policy
                        .insurance_risk.risk_branch.code
                    : ""
                  : "";
              },
            },
            {
              label: this.getDictionary("insurance_risk", "book_entry"),
              value: "insurance_policy_number",
              virtual: (detail) => {
                return detail.various_accountings &&
                  detail.various_accountings.length
                  ? detail.various_accountings[0].pivot.insurance_risk.code
                  : detail.insurance_ancillaries &&
                    detail.insurance_ancillaries.length
                  ? detail.insurance_ancillaries[0].insurance_policy
                    ? detail.insurance_ancillaries[0].insurance_policy
                        .insurance_risk.code
                    : ""
                  : "";
              },
            },
            {
              label: this.getDictionary("Codice compagnia Delegataria"),
              value: "insurer_coass",
            },
            {
              label: this.getDictionary("Nr appendice"),
              value: "appendix",
            },
          ],
        },
        registry: {
          header: "Dati Anagrafici",
          fields: [
            {
              label: this.getDictionary("registry", "book_entry"),
              value: "registry",
              virtual: (detail) => {
                return detail.registries.length
                  ? detail.registries[0].status_registry.value === 0
                    ? `${detail.registries[0].attributables.SURN} ${detail.registries[0].attributables.NAME}`
                    : `${detail.registries[0].attributables.CNAM}`
                  : "";
              },
            },
            {
              label: this.getDictionary("attribute_NINO_value", "registry"),
              value: "registry",
              virtual: (detail) => {
                return detail.registries.length
                  ? detail.registries[0].status_registry.value === 0
                    ? detail.registries[0].attributables.NINO
                    : ""
                  : "";
              },
            },
            {
              label: this.getDictionary("attribute_CREG_value", "registry"),
              value: "registry",
              virtual: (detail) => {
                return detail.registries.length
                  ? detail.registries[0].status_registry.value === 1
                    ? detail.registries[0].attributables.CREG
                    : ""
                  : "";
              },
            },
          ],
        },
        book_entry_premium: {
          header: "Premio",
          repository: "book_entry",
          fields: [
            {
              label: this.getDictionary("net", "book_entry"),
              value: "net",
              type: "currency",
              // value: "",
              // virtual: (detail) => {
              //   return toLocaleCurrency(
              //     detail.net -
              //       this.sumPivot(
              //         detail.insurance_ancillaries[0]?.insurer_participants,
              //         "net"
              //       )
              //   );
              // },
              // REMOVED: type: "currency",
            },
            {
              label: this.getDictionary("tax", "book_entry"),
              value: "tax",
              type: "currency",
              // value: "",
              // virtual: (detail) => {
              //   return toLocaleCurrency(
              //     detail.tax -
              //       this.sumPivot(
              //         detail.insurance_ancillaries[0]?.insurer_participants,
              //         "tax"
              //       )
              //   );
              // },
              // REMOVED: type: "currency",
            },
            {
              label: this.getDictionary("gross", "book_entry"),
              value: "gross",
              type: "currency",
              // value: "",
              // virtual: (detail) => {
              //   return toLocaleCurrency(
              //     detail.gross -
              //       this.sumPivot(
              //         detail.insurance_ancillaries[0]?.insurer_participants,
              //         "gross"
              //       )
              //   );
              // },
              // REMOVED: type: "currency",
            },
          ],
        },
        agency: {
          header: "Provvigioni Agenzia",
          fields: [
            {
              label: this.getDictionary("agency_prov_purchase", "book_entry"),
              value: "agency_prov_purchase",
              type: "currency",
            },
            {
              label: this.getDictionary("agency_prov_take", "book_entry"),
              value: "agency_prov_take",
              type: "currency",
            },
            {
              label: this.getDictionary("total_prov", "book_entry"),
              value: "total_prov",
              type: "currency",
              virtual: (detail) => {
                return detail.agency_prov_purchase + detail.agency_prov_take;
              },
            },
          ],
        },
        saler: {
          header: "Provvigioni Collaboratore",
          fields: [
            {
              label: this.getDictionary("saler_prov_purchase", "book_entry"),
              value: "saler_prov_purchase",
              type: "currency",
            },
            {
              label: this.getDictionary("saler_prov_take", "book_entry"),
              value: "saler_prov_take",
              type: "currency",
            },
            {
              label: this.getDictionary("total_prov", "book_entry"),
              value: "total_prov",
              type: "currency",
              virtual: (detail) => {
                return detail.saler_prov_purchase + detail.saler_prov_take;
              },
            },
          ],
        },
        documents_not_insurance_policy: {
          header: "Importi Documenti Non Su Polizza",
          fields: [
            {
              label: this.getDictionary("Importo registrazione"),
              value: "",
            },
            {
              label: this.getDictionary("Provvigione Agenzia"),
              value: "",
            },
            {
              label: this.getDictionary("Provvigione Collaboratore"),
              value: "",
            },
          ],
        },
      },
      coassFields: {
        coass: {
          header: "Coassicurazioni",
          fields: [
            {
              label: this.getDictionary("Netto"),
              value: "",
              virtual: (detail) => {
                return toLocaleCurrency(
                  this.sumPivot(
                    detail.insurance_ancillaries[0]?.insurer_participants,
                    "net"
                  )
                );
              },
            },
            {
              label: this.getDictionary("Imposte"),
              value: "",
              virtual: (detail) => {
                return toLocaleCurrency(
                  this.sumPivot(
                    detail.insurance_ancillaries[0]?.insurer_participants,
                    "tax"
                  )
                );
              },
            },
            {
              label: this.getDictionary("Lordo"),
              value: "",
              virtual: (detail) => {
                return toLocaleCurrency(
                  this.sumPivot(
                    detail.insurance_ancillaries[0]?.insurer_participants,
                    "gross"
                  )
                );
              },
            },
            {
              label: this.getDictionary("Provvigioni acquisto collaboratore"),
              value: "",
              virtual: (detail) => {
                return toLocaleCurrency(
                  this.sumPivot(
                    detail.insurance_ancillaries[0]?.insurer_participants,
                    "participant_prov_purchase"
                  )
                );
              },
            },
            {
              label: this.getDictionary("Provvigioni incasso collaboratore"),
              value: "",
              virtual: (detail) => {
                return toLocaleCurrency(
                  this.sumPivot(
                    detail.insurance_ancillaries[0]?.insurer_participants,
                    "participant_prov_take"
                  )
                );
              },
            },
          ],
        },
      },
      // thClass: "text-right",
      //     thStyle: {
      //       "text-align": "right",
      //     },
      //     tdClass: "text-right",
      coassDetailFields: [
        { key: "compagnia_delegata", label: "Codice Compagnia" },
        { key: "agenzia_delegata", label: "Codice Agenzia" },
        { key: "descrizione", label: "Descrizione" },
        {
          key: "participant_prov_purchase",
          label: "Provv.Acq.Agente",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "participant_prov_take",
          label: "Provv.Inc.Agente",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "net",
          label: "Imponibile",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "tax",
          label: "Imposte",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "gross",
          label: "Totale",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      coassDetails: [],
    };
  },
  methods: {
    toLocaleCurrency,
    toInfoData,
    toLocaleDate,
    fetch() {
      let skipCodes = ["EN", "PR", "COC", "COP"];
      let queryString = `relations/byRegistry/byTreasury/byBreakdown/byBookTag/byInsurer/byBroker/byInsuranceAncillary/byVariousAccounting/byInsurerAccounting/byInsurerParticipant/byBrokerStatement`; // #1167

      return this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;

          const insurance_policy =
            data.insurance_ancillaries[0]?.insurance_policy;
          if (insurance_policy) {
            if (insurance_policy.sharing_type == 1) {
              this.$set(this, "coassFields", null);
            }
            if (data.insurance_ancillaries[0]?.insurer_participants?.length) {
              this.$set(
                this,
                "coassDetails",
                data.insurance_ancillaries[0].insurer_participants
              );
            }
          } else {
            this.$set(this, "coassFields", null);
          }
          // sort by entry_time
          data.entry_details.sort((a, b) =>
            a.entry_time.localeCompare(b.entry_time)
          );
          this.details = data;

          // check if treasury relation exists card 646
          this.checkTreasuryRelation = data.entry_details.filter(
            (e) => e.treasury
          );

          // list of negative deferred, if any
          const allNegativeDeferred = this.checkTreasuryRelation.filter(
            (e) => e?.treasury?.code === "SS" && e.gross < 0
          );

          if (this.checkTreasuryRelation.length !== 0) {
            let skipIds = []; // list if ids to exclude since they have a pivot relation...
            for (const d of allNegativeDeferred) {
              skipIds.push(d.id); // add itself...
              skipIds = skipIds.concat(d.entry_details.map((e) => e.id)); // add the pivot related ones...
            }

            const filteredEntryDetails = data.entry_details.filter(
              (e) =>
                !skipIds.includes(e.id) &&
                !skipCodes.includes(e?.treasury?.code) &&
                e.detail_date === data.book_date &&
                !e.entry_details.length
            );

            // Clear old Data
            for (let index of this.entryDetails.keys()) {
              // NEW
              this.$delete(this.entryDetails, index);
            }

            // Set new data
            for (let index of filteredEntryDetails.keys()) {
              // NEW
              this.$set(this.entryDetails, index, filteredEntryDetails[index]);
            }
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.form = {};
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    sumPivot(array, field) {
      let initialValue = 0;
      let sum = array.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.pivot[field];
      }, initialValue);
      return sum;
    },
  },
  computed: {
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
    isDelegaNostra() {
      let type = 0;
      return (
        this.details.insurance_ancillaries[0]?.insurance_policy.sharing_type
          .value === type
      );
    },
  },
  beforeMount() {
    this.isLoading = true;
    this.fetch();
    if (this.agencyHasFee) {
      const feeCard = {
        header: "Diritti",
        fields: [
          {
            label: this.getDictionary("fee_net", "book_entry"),
            value: "fee_net",
            type: "currency",
          },
          {
            label: this.getDictionary("fee_tax", "book_entry"),
            value: "fee_tax",
            type: "currency",
          },
          {
            label: this.getDictionary("gross", "book_entry"),
            value: "fee_gross",
            type: "currency",
          },
        ],
      };
      this.$set(this.detailFields, "fee", feeCard);
      const salerFeeTakeField = {
        label: this.getDictionary("saler_fee_take", "book_entry"),
        value: "saler_fee_take",
        type: "currency",
      };
      this.detailFields.saler.fields.push(salerFeeTakeField);
    }
  },
};
</script>

<style scoped lang="scss">
.card {
  border: none;
}
.col-md-4.border-bottom {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
