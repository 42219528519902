<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";
import {
  toInfoData,
  toRelationString,
  joinValues,
  findAndGet,
} from "@/utils/transforms";

export default {
  name: "General",
  mixins: [FormMixin, ShowMixin],
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "claim",
      isLoading: true,
      detailFields: {
        scheda_contatto: {
          header: "Dati Principali",
          edit: false,
          repository: "claim",
          fields: [
            {
              value: "claim_type.text",
              label: this.getDictionary("claim_type", "claim"),
            },
            {
              value: "insurance_policy.insurer.formatted_title",
              label: this.getDictionary("insurer"),
            },
            {
              label: this.getDictionary("number", "claim"),
              value: "number",
              virtual: (detail) => {
                return joinValues([
                  detail.insurance_policy && detail.insurance_policy.insurer
                    ? detail.insurance_policy.insurer.code
                    : null,
                  detail.insurance_policy && detail.insurance_policy.insurer
                    ? // ? this.$store.state.auth.mandate_codes.find(
                      //     (e) => e.id === detail.insurance_policy.mandate_code_id
                      //   ).code
                      findAndGet(
                        this.$store.state.auth.mandate_codes,
                        "id",
                        detail.insurance_policy.mandate_code_id,
                        "code"
                      )
                    : null,
                  detail.referring_at,
                  detail.number,
                ]);
              },
            },
            {
              label: this.getDictionary(
                "attribute_REPL_value",
                "insurance_policy"
              ),
              value: "insurance_policy.attributables.REPL",
            },
            {
              label: this.getDictionary("number", "insurance_policy"),
              value: "insurance_policy.number",
            },
            {
              label: this.getDictionary("place", "claim"),
              value: "place",
            },
            {
              label: this.getDictionary("description", "claim"),
              value: "title",
            },
            {
              label: this.getDictionary("title", "risk_branch"),
              value:
                "insurance_policy.insurance_risk.risk_branch.formatted_title",
            },
            {
              label: this.getDictionary("title", "insurance_risk"),
              value: "insurance_policy.insurance_risk.formatted_title",
            },
            {
              label: this.getDictionary("occurred_at", "claim"),
              value: "occurred_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("salesman"),
              value: "salesman",
              virtual: (detail) => {
                return toInfoData(
                  detail.insurance_policy.broker,
                  "broker",
                  0,
                  null,
                  "\n"
                );
              },
            },
          ],
        },
        dati_anagrafici_contraente: {
          header: "Dati Anagrafici Contraente",
          edit: false,
          repository: "claim",
          fields: [
            {
              label: this.getDictionary("customer_name"),
              value: "customer_name",
              virtual: (detail) => {
                return detail.insurance_policy.registry.status_registry
                  .value === 1
                  ? detail.insurance_policy.registry.attributables.CNAM
                  : detail.insurance_policy.registry.attributables.SURN +
                      " " +
                      detail.insurance_policy.registry.attributables.NAME;
              },
            },
            {
              label: this.getDictionary(
                "attribute_ADDR_value",
                "correspondence"
              ),
              value: "attribute_ADDR_value",
              virtual: (detail) => {
                return detail.insurance_policy.registry
                  ? toInfoData(
                      detail.insurance_policy.registry,
                      "address",
                      0,
                      "-"
                    )
                  : "";
              },
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "address_books",
              virtual: (detail) => {
                return detail.insurance_policy.registry
                  ? toRelationString(
                      "address_books",
                      detail.insurance_policy.registry.address_books,
                      ["MAIL"],
                      [0]
                    )
                  : "";
              },
            },
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "address_books",
              virtual: (detail) => {
                return detail.insurance_policy.registry
                  ? toRelationString(
                      "address_books",
                      detail.insurance_policy.registry.address_books,
                      ["CELL"],
                      [0]
                    )
                  : "";
              },
            },
            {
              label: this.getDictionary("primary_group"),
              value: "primary_group",
              virtual: (detail) => {
                return detail.insurance_policy.registry
                  ? detail.insurance_policy.registry.registry_groups.length &&
                    detail.insurance_policy.registry.registry_groups.find(
                      (registry_group) =>
                        registry_group.pivot.primary.value === "Y"
                    )
                    ? detail.insurance_policy.registry.registry_groups.find(
                        (registry_group) =>
                          registry_group.pivot.primary.value === "Y"
                      ).code
                    : ""
                  : "";
              },
            },
          ],
        },
        dati_controparte: {
          header: "Dati Anagrafici Controparte",
          edit: false,
          repository: "claim",
          fields: [
            {
              label: this.getDictionary("attribute_FULN", "counterpart"),
              value: "attribute_FULN",
              virtual: (detail) => {
                const found = detail.claim_parties.find(
                  (claim_party) => claim_party.status_claimparty.value === 1
                );
                if (found) {
                  return found.attributables.FULN;
                }
                return "";
              },
            },
            {
              label: this.getDictionary("attribute_CPRGP", "counterpart"),
              value: "attribute_CPRGP",
              virtual: (detail) => {
                const found = detail.claim_parties.find(
                  (claim_party) => claim_party.status_claimparty.value === 1
                );
                if (found) {
                  return found.attributables.CPRGP;
                }
                return "";
              },
            },
            {
              label: this.getDictionary("attribute_CPVEH", "counterpart"),
              value: "attribute_CPVEH",
              virtual: (detail) => {
                const found = detail.claim_parties.find(
                  (claim_party) => claim_party.status_claimparty.value === 1
                );
                if (found) {
                  return found.attributables.CPVEH;
                }
                return "";
              },
            },
            {
              label: this.getDictionary("attribute_CPINS", "counterpart"),
              value: "attribute_CPINS",
              virtual: (detail) => {
                const found = detail.claim_parties.find(
                  (claim_party) => claim_party.status_claimparty.value === 1
                );
                if (found) {
                  return found.attributables.CPINS;
                }
                return "";
              },
            },
            {
              label: this.getDictionary("dcpd_module_type", "claim"),
              value: "dcpd_module_type.text",
            },
          ],
        },
        importi: {
          header: "Importi",
          edit: false,
          repository: "claim",
          fields: [
            {
              label: this.getDictionary("settled_total", "claim"),
              value: "settled_total",
              type: "currency",
            },
            {
              label: this.getDictionary("partially_settled_at", "claim"),
              value: "partially_settled_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("settlement_expense", "claim"),
              value: "settlement_expense",
              type: "currency",
            },
            {
              label: this.getDictionary("amount_in_reserve", "claim"),
              value: "amount_in_reserve",
              type: "currency",
            },
            {
              label: this.getDictionary("deductible", "claim"),
              value: "deductible",
              type: "currency",
            },
          ],
        },
        varie: {
          header: "Varie",
          edit: false,
          repository: "claim",
          fields: [
            {
              label: this.getDictionary("inspector", "claim"),
              value: "inspector",
              virtual: (detail) => {
                const found = detail.claim_parties.find(
                  (claim_party) => claim_party.status_claimparty.value === 5
                );
                if (found) {
                  return found.attributables.FULN;
                }
                return "";
              },
            },
            {
              label: this.getDictionary("surveyor", "claim"),
              value: "surveyor",
              virtual: (detail) => {
                const found = detail.claim_parties.find(
                  (claim_party) => claim_party.status_claimparty.value === 2
                );
                if (found) {
                  return found.attributables.FULN;
                }
                return "";
              },
            },
            {
              label: this.getDictionary("dcpd_module_filled", "claim"),
              value: "dcpd_module_filled",
              virtual: (detail) => {
                return detail.dcpd_module_filled.value ? "Si" : "No";
              },
            },
            {
              label: this.getDictionary("is_covered", "claim"),
              value: "is_covered.text",
            },
          ],
        },
      },
    };
  },
  methods: {
    joinValues,
    findAndGet,
    fetchForm() {
      this.isLoading = true;
      let queryString = `relations/byClaimParty/byCorrespondence/byInsurancePolicy/byRegistryGroup/byBroker`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          //Set Detail Card Data
          this.details = data;

          if (!(data.correspondences && data.correspondences.length)) {
            this.$delete(this.detailFields, "scheda_indirizzo");
          }
          this.initDetailCardFormData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
  },
  mounted() {
    this.fetchForm();
  },
  computed: {
    cor() {
      return "correspondence";
    },
  },
};
</script>
